import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IGrossCalcResult } from '../types/railcarLoadingInterfaces';

interface ResultModalProps {
  onClose: () => void;
  show: boolean;
  calcResult: IGrossCalcResult;
}
export const GrossCalculationResults = ({
  onClose,
  show,
  calcResult,
}: ResultModalProps): JSX.Element => {
  const grossCalcMessages = calcResult.Messages.map((msg) => {
    return (
      <tr>
        <td
          className="text-danger text-center font-weight-bold small"
          colSpan={2}
        >
          {msg}
        </td>
      </tr>
    );
  });

  return (
    <Modal show={show} onHide={onClose} animation={false}>
      <Modal.Header>
        <Modal.Title className="font-weight-bold text-primary">
          Results
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Car:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.CarInitial || ''} {calcResult.CarNumber || ''}
              </td>
            </tr>
            <tr>
              <td
                className="text-sm-left font-weight-bold text-primary"
                rowSpan={3}
              >
                Actual Outage :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.ActualOutage || ''} Inches
              </td>
            </tr>
            <tr className="found">
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.OutageGallons || ''} Gallons
              </td>
            </tr>
            <tr>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.PercentageOutage || ''} %
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Liquid Temperature:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.LiquidTemp || ''} F
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Vapor Pressure:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.VaporPressure || ''} PSIG
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Vapor Weight :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.VaporWeight || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Light Weight :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.LightWeight || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Liquid Weight :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.LiquidWeight || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Total Ammonia :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.TotalAmmonia || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Max Allowable :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.MaxAllowable || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Gross Weight:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.GrossWeight || ''} LBS
              </td>
            </tr>
            {grossCalcMessages.length > 0 &&
              grossCalcMessages.map((component) => <>{component}</>)}
            <tr className="found">
              <td colSpan={2}>
                <div className="text-center">
                  <Button variant="primary" onClick={onClose}>
                    Close
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </Modal.Body>
    </Modal>
  );
};
