/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

interface SearchCarFormProps {
  searchHandler: (carInitial: string, carNumber: number) => void;
  resetValidationMessages: () => void;
}

export const CarSearchForm = ({
  searchHandler,
  resetValidationMessages,
}: SearchCarFormProps): JSX.Element => {
  const [searchParams, setSearchParams] = useState({
    CarInitial: '',
    CarNumber: 0,
  });

  const searchCars = (event: React.FormEvent) => {
    event.preventDefault();
    searchHandler(searchParams.CarInitial, searchParams.CarNumber);
  };

  return (
    <>
      <form className="form-inline" onSubmit={searchCars}>
        <label
          htmlFor="CarInitial"
          className="mr-1 mb-2 text-dark font-weight-bolder small"
        >
          Car Initial
        </label>
        <input
          type="text"
          name="CarInitial"
          value={searchParams.CarInitial || ''}
          onChange={(e) => {
            setSearchParams({ ...searchParams, CarInitial: e.target.value });
            resetValidationMessages();
          }}
          className="form-control mb-2 mr-sm-2"
          id="CarInitial"
        />

        <label
          htmlFor="CarNumber"
          className="mr-1 mb-2 text-dark font-weight-bolder small"
        >
          Car Number
        </label>
        <div className="input-group mb-2 mr-sm-2">
          <input
            type="number"
            value={searchParams.CarNumber || ''}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                CarNumber: Number(e.target.value),
              });
              resetValidationMessages();
            }}
            className="form-control"
            id="CarNumber"
          />
        </div>

        <button type="submit" className="btn btn-primary mb-2">
          Search
        </button>
      </form>
    </>
  );
};
