/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
// https://github.com/IdentityModel/oidc-client-js/wiki
import { UserManager } from 'oidc-client';
import type { state as globalState } from '../state/state.service';

export class AuthService {
  constructor(
    private readonly userManager: UserManager,
    private state: typeof globalState,
    private windowRef = window,
  ) {
    this.userManager.events.addAccessTokenExpired(() => {
      this.logout();
    });
    // this.userManager.events.addAccessTokenExpiring(() => {
    // console.log('token expires soon');
    // });
  }

  async init(): Promise<void> {
    const user = await this.getUser();
    if (user) this.state.user = user;
  }

  async login(): Promise<void> {
    await this.userManager.signinRedirect();
  }

  async getUser(): Promise<ReturnType<UserManager['getUser']> | void> {
    try {
      return (
        (await this.userManager.getUser()) ??
        (await this.userManager.signinRedirectCallback())
      );
    } catch (e: any) {
      switch (e.message) {
        case 'No state in response':
          return null;
        case 'user token has expired':
          this.logout();
          return null;
        default:
          throw e;
      }
    }
  }

  async logout(): Promise<void> {
    await this.userManager.removeUser();
    delete this.state.user;
    if (this.windowRef.location.pathname !== '/') {
      this.windowRef.location.replace('/');
    }
  }
}
