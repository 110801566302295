/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { State, state } from '../state/state.service';

interface AlertComponentProps {
  error: State['error'];
  onClick: React.MouseEventHandler;
}

export const AlertComponent = ({
  error,
  onClick,
}: AlertComponentProps): JSX.Element => {
  if (!error) {
    return <></>;
  }
  return (
    <div
      className="alert alert-danger text-center pointer"
      onClick={onClick}
      role="alert"
    >
      {error.message}
    </div>
  );
};

export const Alert = view(() => (
  <AlertComponent error={state.error} onClick={() => delete state.error} />
));
