import { Navigation } from '@kaes/react-components';
import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { authService } from './app.module';
import { Authentication } from './authentication';
import { Login } from './authentication/Login';
import { Alert } from './components/Alert';
import { HomePage, HOME_PAGE_ROUTE } from './components/Home';
import {
  TableAdminPage,
  TABLE_ADMIN_PAGE_ROUTE,
} from './components/TableAdmin';
import { NewCarPage, NEW_CAR_PAGE_ROUTE } from './components/NewCarPage';
import {
  NewOutageTablePage,
  NEW_OUTAGE_TABLE_PAGE_ROUTE,
} from './components/NewOutageTablePage';
import { EditCarPage, EDIT_CAR_PAGE_ROUTE } from './components/EditCarPage';
import {
  EditOutageTablePage,
  EDIT_OUTAGE_TABLE_PAGE_ROUTE,
} from './components/EditOutageTablePage';
import { ViewCarPage, VIEW_CARS_PAGE_ROUTE } from './components/ViewCarPage';
import {
  ViewOutageTablePage,
  VIEW_OUTAGE_TABLE_PAGE_ROUTE,
} from './components/ViewOutageTablePage';
import {
  RoleAdminPage,
  ROLE_ADMIN_PAGE_ROUTE,
} from './components/UserRoleAdmin';
import {
  CalculationsPage,
  CALCULATION_PAGE_ROUTE,
} from './components/Calculations';
import { OutageCalcPage, OUTAGE_PAGE_ROUTE } from './components/OutageCalc';
import { GrossCalcPage, GROSS_PAGE_ROUTE } from './components/GrossCalc';
import {
  AssignRolePage,
  ASSIGN_ROLE_PAGE_ROUTE,
} from './components/AssignRole';
import {
  UnassignRolePage,
  UNASSIGN_ROLE_PAGE_ROUTE,
} from './components/UnassignRole';
import { ListUsersPage, LIST_USERS_PAGE_ROUTE } from './components/ListUsers';
import { PageNotFound } from './components/PageNotFound';
// import { ProtectedPage, PROTECTED_PAGE_ROUTE } from './components/Protected';
import { UserProfilePage, USER_PROFILE_ROUTE } from './components/UserProfile';
import './datadog/datadog.service';

function RailLoadingApp(): JSX.Element {
  const [open, toggle] = useState(false);

  return (
    <Authentication authService={authService}>
      <BrowserRouter>
        <Navigation
          imgURL="/Koch_Logo.png"
          height={50}
          color="white"
          bg="dark"
          className="text-capitalize"
          open={open}
          toggle={toggle}
        >
          <Login authService={authService} />
        </Navigation>

        <Alert />

        <Switch>
          <Route exact path={HOME_PAGE_ROUTE} component={HomePage} />
          <Route
            exact
            path={TABLE_ADMIN_PAGE_ROUTE}
            component={TableAdminPage}
          />
          <Route
            exact
            path={CALCULATION_PAGE_ROUTE}
            component={CalculationsPage}
          />
          <Route exact path={ROLE_ADMIN_PAGE_ROUTE} component={RoleAdminPage} />
          <Route
            exact
            path={ASSIGN_ROLE_PAGE_ROUTE}
            component={AssignRolePage}
          />
          <Route
            exact
            path={UNASSIGN_ROLE_PAGE_ROUTE}
            component={UnassignRolePage}
          />
          <Route exact path={LIST_USERS_PAGE_ROUTE} component={ListUsersPage} />
          <Route exact path={OUTAGE_PAGE_ROUTE} component={OutageCalcPage} />
          <Route exact path={GROSS_PAGE_ROUTE} component={GrossCalcPage} />
          <Route exact path={NEW_CAR_PAGE_ROUTE} component={NewCarPage} />
          <Route
            exact
            path={NEW_OUTAGE_TABLE_PAGE_ROUTE}
            component={NewOutageTablePage}
          />
          <Route exact path={EDIT_CAR_PAGE_ROUTE} component={EditCarPage} />
          <Route
            exact
            path={EDIT_OUTAGE_TABLE_PAGE_ROUTE}
            component={EditOutageTablePage}
          />
          <Route exact path={VIEW_CARS_PAGE_ROUTE} component={ViewCarPage} />
          <Route
            exact
            path={VIEW_OUTAGE_TABLE_PAGE_ROUTE}
            component={ViewOutageTablePage}
          />
          {/* <Route exact path="/style-guide" component={StyleGuide} /> */}
          <Route
            exact
            path={USER_PROFILE_ROUTE}
            component={() => <UserProfilePage authService={authService} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </Authentication>
  );
}

export default RailLoadingApp;
