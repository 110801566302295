/* eslint-disable no-console */

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { state } from '../state/state.service';
import { config } from './Home';
import { CalculationService } from '../services/calculation.services';
import {
  IGrossCalcResult,
  IGrossCalcInput,
} from '../types/railcarLoadingInterfaces';
import { GrossCalculationResults } from './GrossCalculationResults';

export const GROSS_PAGE_ROUTE = '/GrossCalc';
const schema = yup
  .object({
    carInitial: yup
      .string()
      .uppercase('Car initial should be in uppercase')
      .min(4, 'Must be exactly 4 characters')
      .max(4, 'Must be exactly 4 characters')
      .required(),
    carNumber: yup
      .number()
      .typeError('Car number must be a number')
      .positive()
      .integer()
      .required(),
    temp: yup
      .number()
      .typeError('Temperature must be a number')
      .positive()
      .integer()
      .required(),
    pressure: yup
      .number()
      .typeError('Pressure must be a number')
      .positive()
      .integer()
      .required(),
    outage: yup
      .number()
      .typeError('Outage inches must be a number')
      .positive()
      .integer()
      .required(),
  })
  .required();

export function GrossCalcPage(): JSX.Element {
  const [show, setShow] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [grossCalcResult, setGrossCalcResult] = useState<IGrossCalcResult>({
    Messages: [],
  });
  const [grossCalcParams, setGrossCalcParams] = useState<IGrossCalcInput>({
    CarInitial: '',
    CarNumber: 0,
    Temp: 0,
    Pressure: 0,
    OutageInches: 0,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [validationMessages, setValidationMessages] = useState<string[]>([]);
  const resetValidationMessages = () => {
    setValidationMessages([]);
  };
  const calculateGrossWeight = async () => {
    // event.preventDefault();
    console.log(grossCalcParams);
    setCalculating(true);
    const calculationService = new CalculationService(axios, config, state);
    const result = await calculationService.calculateGrossWeight(
      grossCalcParams,
    );
    if (result) {
      console.log(result);
      setGrossCalcResult(result);
      setCalculating(false);
      setShow(true);
    }
  };

  const hideResultModal = () => {
    setShow(false);
  };
  const validationMessageList = validationMessages.length
    ? validationMessages.map((msg) => {
        return (
          <li className="text-danger text-center font-weight-bold small">
            {msg}
          </li>
        );
      })
    : null;
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/Calulations' }}>
          <small className="font-italic font-weight-bold text-primary">
            Calculations
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            Car Gross Calculation
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1">
        Calculate Rail Car Gross
      </p>
      {validationMessages.length > 0 && (
        <div className="d-flex justify-content-center ">
          <ul>{validationMessageList}</ul>
        </div>
      )}
      {calculating && (
        <div className="d-flex justify-content-center ">
          <Loader
            type="ThreeDots"
            color="#123966"
            height={50}
            width={60}
            timeout={5000} // 5 secs
          />
        </div>
      )}
      <div className="d-flex justify-content-center ">
        <div className="col-md-4">
          <form
            className="card p-1"
            onSubmit={handleSubmit(calculateGrossWeight)}
          >
            <div className="form-group mb-0">
              <label
                htmlFor="carInitial"
                className="text-dark font-weight-bolder small"
              >
                Car Initial*
              </label>
              <input
                type="text"
                {...register('carInitial')}
                value={grossCalcParams.CarInitial || ''}
                onChange={(e) => {
                  setGrossCalcParams({
                    ...grossCalcParams,
                    CarInitial: e.target.value,
                  });
                  resetValidationMessages();
                }}
                className="form-control"
                id="carInitial"
              />
              <p className="text-danger small">{errors.carInitial?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="carNumber"
                className="text-dark font-weight-bolder small"
              >
                Car Number*
              </label>
              <input
                type="number"
                {...register('carNumber')}
                value={grossCalcParams.CarNumber || ''}
                onChange={(e) => {
                  setGrossCalcParams({
                    ...grossCalcParams,
                    CarNumber: Number(e.target.value),
                  });
                  resetValidationMessages();
                }}
                className="form-control"
                id="carNumber"
              />
              <p className="text-danger small">{errors.carNumber?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="temp"
                className="text-dark font-weight-bolder small"
              >
                NH3 Temp* (F)
              </label>
              <input
                type="number"
                {...register('temp')}
                value={grossCalcParams.Temp || ''}
                onChange={(e) => {
                  setGrossCalcParams({
                    ...grossCalcParams,
                    Temp: Number(e.target.value),
                  });
                  resetValidationMessages();
                }}
                className="form-control"
                id="temp"
              />
              <p className="text-danger small">{errors.temp?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="pressure"
                className="text-dark font-weight-bolder small"
              >
                Pressure* (PSIG)
              </label>
              <input
                type="number"
                {...register('pressure')}
                value={grossCalcParams.Pressure || ''}
                onChange={(e) => {
                  setGrossCalcParams({
                    ...grossCalcParams,
                    Pressure: Number(e.target.value),
                  });
                  resetValidationMessages();
                }}
                className="form-control"
                id="pressure"
              />
              <p className="text-danger small">{errors.pressure?.message}</p>
            </div>
            <div className="form-group mb-0">
              <label
                htmlFor="outage"
                className="text-dark font-weight-bolder small"
              >
                Outage* (Inches)
              </label>
              <input
                type="number"
                {...register('outage')}
                value={grossCalcParams.OutageInches || ''}
                onChange={(e) => {
                  setGrossCalcParams({
                    ...grossCalcParams,
                    OutageInches: Number(e.target.value),
                  });
                  resetValidationMessages();
                }}
                className="form-control"
                id="outage"
              />
              <p className="text-danger small">{errors.outage?.message}</p>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-sm">
                Calculate
              </button>
            </div>
          </form>
          <GrossCalculationResults
            show={show}
            calcResult={grossCalcResult}
            onClose={hideResultModal}
          />
        </div>
      </div>
    </>
  );
}
