import { datadogRum } from '@datadog/browser-rum';
import { ConfigService } from '../config/config.service';

const config = new ConfigService().from({}, { groups: ['browser'] });
let datadog;

if (!datadog) {
  datadog = datadogRum.init({
    site: 'datadoghq.com',
    applicationId: config.DATADOG_APP_ID,
    clientToken: config.DATADOG_CLIENT_TOKEN,
    service: config.PACKAGE_NAME,
    env: config.ENVIRONMENT,
    version: config.PACKAGE_VERSION,
    sampleRate: 100,
    trackInteractions: config.DATADOG_TRACK_INTERACTIONS,
  });
}
