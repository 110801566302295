/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosStatic } from 'axios';
import type { State } from '../state/state.service';
import { ConfigService } from '../config/config.service';
import { authService } from '../app.module';
import BaseService from './base.services';
import {
  IOutageTableEntry,
  IOutageTableId,
} from '../types/railcarLoadingInterfaces';

export class OutageTableService extends BaseService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly config: ConfigService,
    private readonly state: State,
  ) {
    super(authService);
  }

  async getOutageTable(
    outageTableId: number,
  ): Promise<IOutageTableEntry[] | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.get(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/outage-table`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
          params: { outageTableId },
        },
      );
      if (data.statusCode === 200) {
        return JSON.parse(data.body) as IOutageTableEntry[];
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async getOutageTableIds(
    tableIdPattern: string,
  ): Promise<IOutageTableId[] | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.get(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/outage-table`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
          params: { tableIdPattern },
        },
      );
      if (data.statusCode === 200) {
        return JSON.parse(data.body) as IOutageTableId[];
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async saveOutageTable(
    outageTable: IOutageTableEntry[],
  ): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.post(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/outage-table`,
        outageTable,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      if (data.statusCode === 200) {
        return data.body;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async updateOutageTable(
    outageTable: IOutageTableEntry[],
  ): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.put(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/outage-table`,
        outageTable,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      if (data.statusCode === 200) {
        return data.body;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async deleteOutageTable(outageTableId: number): Promise<string | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.delete(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/outage-table`,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
          params: { outageTableId },
        },
      );
      if (data.statusCode === 200) {
        return data.body;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }
}
