import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { state } from '../state/state.service';
import { NEW_CAR_PAGE_ROUTE } from './NewCarPage';
import { NEW_OUTAGE_TABLE_PAGE_ROUTE } from './NewOutageTablePage';
import { VIEW_CARS_PAGE_ROUTE } from './ViewCarPage';
import { VIEW_OUTAGE_TABLE_PAGE_ROUTE } from './ViewOutageTablePage';

export const TABLE_ADMIN_PAGE_ROUTE = '/TableAdmin';

export function TableAdminPage(): JSX.Element {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            Table Admin
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="row justify-content-center">
        <div className="col-auto">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="text-center">Rail Cars</th>
                <th className="text-center">Outage Table</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-baseline">
                  {state.userRole && state.userRole === 'admin' && (
                    <Link
                      to={VIEW_CARS_PAGE_ROUTE}
                      className="btn btn-primary btn-xs btn-block"
                    >
                      View/Edit/Delete Rail Car
                    </Link>
                  )}
                  {state.userRole && state.userRole === 'read-only' && (
                    <Link
                      to={VIEW_CARS_PAGE_ROUTE}
                      className="btn btn-primary btn-xs btn-block"
                    >
                      View Rail Car
                    </Link>
                  )}
                </td>
                <td className="align-baseline">
                  {state.userRole && state.userRole === 'admin' && (
                    <Link
                      to={VIEW_OUTAGE_TABLE_PAGE_ROUTE}
                      className="btn btn-primary btn-xs btn-block"
                    >
                      View/Edit/Delete Outage Table
                    </Link>
                  )}
                  {state.userRole && state.userRole === 'read-only' && (
                    <Link
                      to={VIEW_OUTAGE_TABLE_PAGE_ROUTE}
                      className="btn btn-primary btn-xs btn-block"
                    >
                      View Outage Table
                    </Link>
                  )}
                </td>
              </tr>
              {state.userRole && state.userRole === 'admin' && (
                <tr>
                  <td className="align-baseline">
                    <Link
                      to={NEW_CAR_PAGE_ROUTE}
                      className="btn btn-primary btn-xs btn-block"
                    >
                      Enter New Car
                    </Link>
                  </td>
                  <td className="align-baseline">
                    <Link
                      to={NEW_OUTAGE_TABLE_PAGE_ROUTE}
                      className="btn btn-primary btn-xs btn-block"
                    >
                      Enter New Outage Table
                    </Link>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
