/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */

import React, { useState } from 'react';
import { AsyncTypeahead, TypeaheadLabelKey } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { OutageTableService } from '../services/outagetable.services';
import { state } from '../state/state.service';
import { IOutageTableId } from '../types/railcarLoadingInterfaces';
import { config } from './Home';

interface SearchOutageTableProps {
  searchOutageTableHandler: (outageTableId: number) => void;
  resetValidationMessages: () => void;
  initVal: string;
  updateCar?: (outageTableId: string, selectedExisting: boolean) => void | null;
}

export const OutageTableSearchForm = ({
  searchOutageTableHandler,
  resetValidationMessages,
  initVal,
  updateCar,
}: SearchOutageTableProps): JSX.Element => {
  const [searchedId, setSearchedId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [singleSelections, setSingleSelections] = useState([]);
  const [options, setOptions] = useState<any>([]);
  const lblKey = 'OutageTableId' as TypeaheadLabelKey<string>;
  const handleSearch = async (query: string) => {
    console.log('Query', query);
    setSearchedId(query);
    const outageTableService = new OutageTableService(axios, config, state);
    const result = await outageTableService.getOutageTableIds(query);
    if (result) {
      console.log('Suggested IDs', result);
      setOptions(result);
    }
  };

  const onBlurHandler = (event: Event) => {
    resetValidationMessages();
    if (updateCar) {
      const enteredValue = (event.target as HTMLInputElement).value;
      let selectedExisting = false;
      if (
        singleSelections.length > 0 &&
        (singleSelections[0] as IOutageTableId).OutageTableId
      ) {
        const selectedId = (singleSelections[0] as IOutageTableId)
          .OutageTableId;
        if (enteredValue === selectedId) {
          selectedExisting = true;
        }
      }
      updateCar(enteredValue, selectedExisting);
    }
  };
  const searchOutageTable = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(singleSelections);
    console.log('Searched for', searchedId);
    if (
      singleSelections.length > 0 &&
      (singleSelections[0] as IOutageTableId).OutageTableId
    ) {
      const selectedId = (singleSelections[0] as IOutageTableId).OutageTableId;
      console.log('Selected ID:', selectedId);
      if (selectedId) {
        searchOutageTableHandler(Number(selectedId));
      }
    } else {
      searchOutageTableHandler(Number(searchedId));
    }
  };
  const filterBy = () => true;
  if (updateCar) {
    return (
      <AsyncTypeahead
        filterBy={filterBy}
        id="outage-table-search"
        isLoading={isLoading}
        minLength={2}
        labelKey={lblKey}
        onSearch={handleSearch}
        onChange={setSingleSelections}
        onBlur={onBlurHandler}
        options={options}
        placeholder={initVal}
        emptyLabel="Table doesn't exist"
        selected={singleSelections}
      />
    );
  }
  return (
    <>
      <form className="form-inline" onSubmit={searchOutageTable}>
        <label
          htmlFor="OutageTableId"
          className="mr-1 mb-2 mt-2 text-dark font-weight-bolder small"
        >
          Outage Table ID
        </label>
        <AsyncTypeahead
          filterBy={filterBy}
          id="outage-table-search"
          isLoading={isLoading}
          minLength={2}
          labelKey={lblKey}
          onSearch={handleSearch}
          onChange={setSingleSelections}
          options={options}
          placeholder={initVal}
          emptyLabel="Table doesn't exist"
          selected={singleSelections}
        />
        <button type="submit" className="btn btn-primary mb-2 ml-2 mt-2">
          Search
        </button>
      </form>
    </>
  );
};

OutageTableSearchForm.defaultProps = {
  updateCar: null,
};
