/* eslint-disable @typescript-eslint/no-explicit-any */
import { observe } from '@nx-js/observer-util';
import { store } from '@risingstack/react-easy-state';
import cache from 'lscache';
import { User } from 'oidc-client';
import {
  IRailCar,
  IOutageTableEntry,
  IUser,
} from 'src/types/railcarLoadingInterfaces';

export interface State {
  demo?: Record<string, unknown>;
  selectedRailCar?: IRailCar;
  selectedOutageTable?: IOutageTableEntry[];
  userList?: IUser[];
  user?: User;
  userRole?: string;
  error?: Error;
}

const localCache = (
  state: Record<string, any>,
  path: string,
  exp?: number,
): boolean | void =>
  state?.[path] ? cache.set(path, state[path], exp) : cache.remove(path);

const current: State = {
  user: cache.get('user'),
};

export const state: State = store(current);

observe(() => {
  localCache(state, 'user');
});
