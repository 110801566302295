/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosStatic } from 'axios';
import type { State } from '../state/state.service';
import { ConfigService } from '../config/config.service';
import { authService } from '../app.module';
import BaseService from './base.services';
import {
  IOutageCalcResult,
  IGrossCalcInput,
  IGrossCalcResult,
} from '../types/railcarLoadingInterfaces';

export class CalculationService extends BaseService {
  constructor(
    private readonly backend: AxiosStatic,
    private readonly config: ConfigService,
    private readonly state: State,
  ) {
    super(authService);
  }

  async calculateOutage(
    CarInitial: string,
    CarNumber: number,
    Temp: number,
  ): Promise<IOutageCalcResult | null> {
    try {
      const user = await this.checkLoginStatus();
      const calcParams: {
        CarInitial: string;
        CarNumber: number;
        Temp: number;
      } = {
        CarInitial,
        CarNumber,
        Temp,
      };

      const { data } = await this.backend.post(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/calc/outage`,
        calcParams,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      if (data.statusCode === 200) {
        return JSON.parse(data.body) as IOutageCalcResult;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }

  async calculateGrossWeight(
    calculationParams: IGrossCalcInput,
  ): Promise<IGrossCalcResult | null> {
    try {
      const user = await this.checkLoginStatus();
      const { data } = await this.backend.post(
        `${this.config.REACT_APP_BACKEND_API_URL}/railcar-loading/calc/gross`,
        calculationParams,
        {
          headers: {
            authorization: `Bearer ${user?.access_token}`,
          },
        },
      );
      if (data.statusCode === 200) {
        return JSON.parse(data.body) as IGrossCalcResult;
      }
    } catch (e: any) {
      this.state.error = e;
    }
    return null;
  }
}
