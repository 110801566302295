import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IOutageCalcResult } from '../types/railcarLoadingInterfaces';

interface ResultModalProps {
  onClose: () => void;
  show: boolean;
  calcResult: IOutageCalcResult;
  calcParams: {
    CarInitial: string;
    CarNumber: number;
    Temp: number;
  };
}
export const OutageCalculationResults = ({
  onClose,
  show,
  calcResult,
  calcParams,
}: ResultModalProps): JSX.Element => {
  return (
    <Modal show={show} onHide={onClose} animation={false}>
      <Modal.Header>
        <Modal.Title className="font-weight-bold text-primary">
          Results
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Car:
              </td>
              <td className=" text-sm-center text-steel-blue font-weight-bold">
                {calcResult.CarInitial || ''} {calcResult.CarNumber || ''}
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Capacity :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.Capacity || ''} GAL
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                LightWeight:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.LightWeight || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Temperature:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcParams.Temp || ''}F
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Density:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.Density || ''} LBS/GAL
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Meter Setting :
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.Meter || ''} LBS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Outage:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.Outage || ''} GALLONS
              </td>
            </tr>
            <tr>
              <td className="text-sm-left font-weight-bold text-primary">
                Gauge Rod:
              </td>
              <td className="text-sm-center text-steel-blue font-weight-bold">
                {calcResult.GaugeRod || ''} Inches
              </td>
            </tr>
            {calcResult.Message.length > 0 && (
              <tr>
                <td className="text-sm-left font-weight-bold text-primary">
                  Message:
                </td>
                <td className="text-danger text-center font-weight-bold small">
                  {calcResult.Message || ''}
                </td>
              </tr>
            )}
            <tr className="found">
              <td colSpan={2}>
                <div className="text-center">
                  <Button variant="primary" onClick={onClose}>
                    Close
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
      </Modal.Body>
    </Modal>
  );
};
