import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

export const CALCULATION_PAGE_ROUTE = '/Calulations';

export function CalculationsPage(): JSX.Element {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            Calculations
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-auto">
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td className="align-baseline">
                    <Link
                      to="/OutageCalc"
                      className="btn btn-primary btn-xs btn-block"
                    >
                      <div style={{ width: '160px' }}>Calculate Outage</div>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="align-baseline">
                    <Link
                      to="/GrossCalc"
                      className="btn btn-primary btn-xs btn-block"
                    >
                      Calculate Gross Weight
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
