/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent, useEffect } from 'react';
import type { AuthService } from '.';

export const Authentication: FunctionComponent<{
  authService: AuthService;
}> = ({ children, authService }): any => {
  useEffect(() => {
    authService.init();
  }, [authService]);

  return children;
};
