import React from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CarForm from './CarForm';

export const EDIT_CAR_PAGE_ROUTE = '/EditCar';

export function EditCarPage(): JSX.Element {
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/TableAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            Table Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/ViewCars' }}>
          <small className="font-italic font-weight-bold text-primary">
            View/Edit/Delete Cars
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            Edit Car
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1 small">
        ENID NH3 Rail - Edit Rail Car
      </p>
      <CarForm isEdit />
    </>
  );
}
