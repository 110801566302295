import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const RailLoadingHelp = (): JSX.Element => {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="hi" className="show">
          <p>Need help?</p>
          <p>
            {' '}
            Enid Power Users (How-To Questions): - Roni Kitchen, Haden Hedges,
            Alan Geesling{' '}
          </p>
          <p>
            Permissions Issues (SharePoint, PowerApp): - Roni Kitchen, Alan
            Geesling{' '}
          </p>
          <p>
            Shipping Clerks can help enter new rail cars and new outage tables
            that are not found by the app.{' '}
          </p>
          <p>
            Technical Support (Problems with the app): - Enter a Compass ticket
            - Contact the AAA Team
          </p>
        </Tooltip>
      }
    >
      <img width="45px" src="/help-icon.png" alt="Help" />
    </OverlayTrigger>
  );
};
