import { User } from 'oidc-client';
import type { AuthService } from '../authentication';

export default class BaseService {
  constructor(private readonly authService: AuthService) {}

  protected async checkLoginStatus(): Promise<User> {
    const user = await this.authService.getUser();
    if (!user) throw new Error('NotLoggedInError: user is not logged in');
    return user;
  }
}
