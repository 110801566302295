import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { state } from '../state/state.service';
import { IRailCar } from '../types/railcarLoadingInterfaces';
import CarForm from './CarForm';

export const NEW_CAR_PAGE_ROUTE = '/NewCar';

export function NewCarPage(): JSX.Element {
  useEffect(() => {
    if (state.selectedRailCar) {
      const railCar: IRailCar = {
        Initial: '',
        CarNumber: 0,
        CarCapacity: 0,
        LightWeight: 0,
        OutageTableId: 0,
      };
      state.selectedRailCar = railCar;
    }
  }, []);
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          <small className="font-italic font-weight-bold text-primary">
            Home
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/TableAdmin' }}>
          <small className="font-italic font-weight-bold text-primary">
            Table Admin
          </small>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          <small className="font-italic font-weight-bold text-gray">
            New Car
          </small>
        </Breadcrumb.Item>
      </Breadcrumb>
      <p className="font-weight-bold text-primary text-center mt-1 small">
        ENID NH3 Rail - Enter New Rail Car
      </p>
      <CarForm isEdit={false} />
    </>
  );
}
