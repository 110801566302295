import { Button, JSONBlock } from '@kaes/react-components';
import { view } from '@risingstack/react-easy-state';
import React from 'react';
import { AuthService } from 'src/authentication';
import { State, state } from '../state/state.service';

type OnClick = React.DOMAttributes<HTMLButtonElement>['onClick'];

const LoginButton = ({ onClick, text }: { onClick: OnClick; text: string }) => (
  <Button float className="btn-primary" onClick={onClick}>
    {text}
  </Button>
);

export const USER_PROFILE_ROUTE = '/user-profile';
export interface UserProfilePageProps {
  authService: AuthService;
}
export interface UserProfileComponentProps extends UserProfilePageProps {
  user: State['user'];
}
export const UserProfileComponent = ({
  user,
  authService,
}: UserProfileComponentProps): JSX.Element => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 mx-auto mt-5">
          <h1 className="text-center">
            {user ? user.profile.email : 'Please Login'}
          </h1>

          <JSONBlock data={user?.profile ?? {}} />

          {!user && (
            <LoginButton onClick={() => authService.login()} text="Login" />
          )}
          {user && (
            <LoginButton onClick={() => authService.logout()} text="Logout" />
          )}
        </div>
      </div>
    </div>
  );
};

export const UserProfilePage = view(({ authService }: UserProfilePageProps) => (
  <UserProfileComponent authService={authService} user={state.user} />
));
